import * as React from "react";

export interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Spinner = ({ size = 44, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22 8.70834V11.4583" stroke="currentColor" strokeWidth={2} />
      <path
        d="M31.3986 12.6014L29.4541 14.5459"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path d="M35.2915 22L32.5415 22" stroke="currentColor" strokeWidth={2} />
      <path
        d="M31.3986 31.3986L29.4541 29.4541"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path d="M22 32.5417V35.2917" stroke="currentColor" strokeWidth={2} />
      <path
        d="M14.5461 29.4541L12.6016 31.3986"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path d="M11.4585 22L8.7085 22" stroke="currentColor" strokeWidth={2} />
      <path
        d="M14.5461 14.5459L12.6016 12.6014"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
};
