import Avatar from "../../avatars/Avatar";
import Loader from "../../loaders/Loader";
import { EmojiSad as EmojiSadIcon } from "@/client/components/icons/emoji-sad";

interface SearchResults {
  results: { title: string; items: ResultsItem[] }[];
  state: "results" | "loading" | "empty";
  handleClick?: any;
}

const SearchResults = ({
  results,
  state = "loading",
  handleClick,
}: SearchResults) => {
  const renderPrimarySection = () => {
    switch (state) {
      case "loading":
        return (
          <div className="py-2">
            <Loader label="Searching..." />
          </div>
        );
      case "results":
        return (
          <>
            {results?.map((section, idx) => (
              <div className="pb-1" key={idx}>
                <ResultsSectionHeader title={section.title} />
                <div>
                  {section.items.map((item, idx) => {
                    return (
                      <button
                        className="w-full block"
                        key={idx}
                        onClick={() =>
                          handleClick(section.title.toLowerCase(), item)
                        }
                      >
                        <ResultsItem {...item} />
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </>
        );
      case "empty":
        return (
          <div className="p-2 flex flex-col justify-center items-center">
            <EmojiSadIcon className="text-neutral-600 dark:text-neutral-400" />
            <p className="text-p-xs text-neutral-600 dark:text-neutral-400">
              Uh oh... nothing found
            </p>
          </div>
        );
    }
  };

  return (
    <div className="shadow-sm divide-y dark:divide-neutral-800 border border-neutral-200 overflow-hidden bg-white dark:bg-neutral-900 dark:border-neutral-800 rounded-xl">
      {renderPrimarySection()}
    </div>
  );
};

interface ResultsSectionHeader {
  title: string;
}

const ResultsSectionHeader = ({ title }: ResultsSectionHeader) => {
  return (
    <div className="h-7 flex flex-col justify-end px-2">
      <p className="dark:text-white text-cap-s mb-1 font-medium uppercase tracking-widest">
        {title}
      </p>
    </div>
  );
};

interface ResultsItem {
  id?: string;
  imageUrl: string;
  imageRounding?: "normal" | "full";
  title: string;
  subline?: string;
  contractAddress?: string;
  tokenId?: string;
}

const ResultsItem = ({
  id,
  imageUrl,
  title,
  imageRounding = "normal",
  subline,
  contractAddress,
  tokenId,
}: ResultsItem) => {
  return (
    <div className="flex items-center px-2 py-1 text-left gap-2 dark:hover:bg-neutral-800 hover:bg-neutral-100 transition">
      <Avatar size="32" rounding={imageRounding} imageUrl={imageUrl} />
      <div className="flex flex-col">
        <p className="text-p-s dark:text-white font-medium line-clamp-1">
          {title}
        </p>
        {subline ? (
          <p className="text-p-xs dark:text-neutral-400 text-neutral-600 -mt-[2px] line-clamp-1">
            {subline}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default SearchResults;
