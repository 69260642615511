import { ChainId } from "@/shared/chains";

const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_CLIENT_API_KEY;

const RPC_URL_ALCHEMY_ETH_MAINNET = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`;
const RPC_URL_ALCHEMY_ETH_SEPOLIA = `https://eth-sepolia.g.alchemy.com/v2/${ALCHEMY_API_KEY}`;

export const getRpcUrl = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.Ethereum:
      return RPC_URL_ALCHEMY_ETH_MAINNET;
    case ChainId.Sepolia:
      return RPC_URL_ALCHEMY_ETH_SEPOLIA;
    default:
      throw new Error(`Unsupported chainId: ${chainId}`);
  }
};
