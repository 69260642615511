import {
  ReservoirClientActions,
  ReservoirClientOptions,
  ReservoirChain,
  reservoirChains,
} from "@reservoir0x/reservoir-sdk";
import { parseUnits } from "viem";

import { parseTokenId } from "./artblocks";
import { isTestnet } from "./config";
import {
  ARTBLOCKS_ADDRESS_GOERLI,
  ARTBLOCKS_EXPLORATIONS_ADDRESS,
  ARTBLOCKS_V1_ADDRESS,
  ARTBLOCKS_V2_ADDRESS,
  ARTBLOCKS_V3_ADDRESS,
  ARTBLOCKS_X_PACE_ADDRESS,
  BRAIN_DROPS_ADDRESS,
  BRIGHT_MOMENTS_ADDRESS,
  BRIGHT_MOMENTS_CRYPTO_CITIZENS_ADDRESS,
  PLOTTABLES_AB_ADDRESS,
  VCA_ADDRESS,
} from "./labelledAddresses";
import {
  getMarketplaceListingFeeAddress,
  getMarketplaceListingFeeBps,
} from "@/shared/marketplaceFees";
import { Market } from "./marketplaces";

const SUPPORTED_ARTBLOCKS_ID_CONTRACTS_TESTNET = [ARTBLOCKS_ADDRESS_GOERLI];
const SUPPORTED_ARTBLOCKS_ID_CONTRACTS_MAINNET = [
  ARTBLOCKS_V1_ADDRESS,
  ARTBLOCKS_V2_ADDRESS,
  ARTBLOCKS_V3_ADDRESS,
  ARTBLOCKS_X_PACE_ADDRESS,
  BRIGHT_MOMENTS_CRYPTO_CITIZENS_ADDRESS,
  BRIGHT_MOMENTS_ADDRESS,
  ARTBLOCKS_EXPLORATIONS_ADDRESS,
  PLOTTABLES_AB_ADDRESS,
  VCA_ADDRESS,
  BRAIN_DROPS_ADDRESS, // Not artblocks but same token Id format
];

export const SUPPORTED_ARTBLOCKS_ID_CONTRACTS = isTestnet()
  ? SUPPORTED_ARTBLOCKS_ID_CONTRACTS_TESTNET
  : SUPPORTED_ARTBLOCKS_ID_CONTRACTS_MAINNET;

const BASE_URL_MAINNET = "https://api.reservoir.tools";
const BASE_URL_TESTNET = "https://api-sepolia.reservoir.tools";
export const BASE_URL = isTestnet() ? BASE_URL_TESTNET : BASE_URL_MAINNET;
const BASE_WS_URL_MAINNET = "wss://ws.reservoir.tools";
const BASE_WS_URL_TESTNET = "wss://ws.dev.reservoir.tools";
export const BASE_WS_URL = isTestnet()
  ? BASE_WS_URL_TESTNET
  : BASE_WS_URL_MAINNET;

export const PROXY_PATH = "/api/reservoir";
export const SOURCE = "sansa.xyz";

const FEE_WALLET_ADDRESS = getMarketplaceListingFeeAddress();

const CHAIN = isTestnet() ? reservoirChains.sepolia : reservoirChains.mainnet;
const CHAINS: ReservoirChain[] = [
  {
    ...CHAIN,
    baseApiUrl: BASE_URL,
    active: true,
  },
];

const getMarketplaceFees = () => {
  return [`${FEE_WALLET_ADDRESS}:${getMarketplaceListingFeeBps()}`];
};

export const RESEVOIR_CLIENT_OPTIONS: ReservoirClientOptions = {
  chains: CHAINS,
  source: SOURCE,
  marketplaceFees: getMarketplaceFees(),
  automatedRoyalties: true,
  normalizeRoyalties: true,
};

type ReservoirBidData = Parameters<
  ReservoirClientActions["placeBid"]
>["0"]["bids"][0];

const isSupportedArtblocksId = (contractAddress: string) => {
  return SUPPORTED_ARTBLOCKS_ID_CONTRACTS.includes(contractAddress);
};

export const buildTokenId = (contractAddress: string, tokenId: string) => {
  return `${contractAddress}:${tokenId}`;
};

export const getCollectionIdByToken = (
  contractAddress: string,
  tokenId: string
) => {
  if (!isSupportedArtblocksId(contractAddress)) return contractAddress;

  const { projectId } = parseTokenId(tokenId);
  return getArtblocksCollectionId(contractAddress, `${projectId}`);
};

export const getCollectionId = (collection: any) => {
  if (
    !isSupportedArtblocksId(collection.contractAddress) ||
    !collection.artBlocksProjectId
  )
    return collection.contractAddress;

  return getArtblocksCollectionId(
    collection.contractAddress,
    collection.artBlocksProjectId
  );
};

const getArtblocksCollectionId = (
  contractAddress: string,
  artblocksProjectId: string
) => {
  let startToken = `${artblocksProjectId}000000`;
  let endToken = `${artblocksProjectId}999999`;
  if (Number(artblocksProjectId) === 0) {
    startToken = `0`;
    endToken = `999999`;
  }

  return `${contractAddress}:${startToken}:${endToken}`;
};
