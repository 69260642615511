import { WalletClient } from "viem";
import { useWalletClient } from "wagmi";
import { useAuth } from "@/client/components/common/AuthProvider";

/**
 * Custom hook that returns an instance of `WalletClient` only if the user is authenticated.
 * This ensures that wallet-related UI components and functionalities are only accessible
 * or initiated when the user has successfully authenticated, helping to keep the UI
 * in sync with the user's authentication state.
 *
 * @returns {WalletClient | null} An instance of `WalletClient` if the user is authenticated,
 * otherwise `null`.
 */
export function useAuthenticatedWalletClient(): WalletClient | undefined {
  const { data: walletClient } = useWalletClient();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return walletClient;
  }

  return undefined;
}
