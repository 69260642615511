import { joinNewsletter } from "client/lib/api";
import { useEffect, useState } from "react";
import { isBrowser } from "shared/config";
import { Close } from "../icons/close";

const NewsletterBox = () => {
  const [input, setInput] = useState<any>(null);
  const [error, setError] = useState<any>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmission = async () => {
    let res = await joinNewsletter(input);

    if (!input || input.length === 0) {
      return;
    }

    if (res?.message === "success") {
      setSuccess(true);
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    dontShowAgain();
    setHidden(true);
  };

  const dontShowAgain = () => {
    if (isBrowser() && localStorage) {
      localStorage?.setItem("sansa--show-newsletter", "false");
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  if (
    isBrowser() &&
    localStorage &&
    localStorage?.getItem("sansa--show-newsletter") === "false"
  ) {
    return null;
  }

  return (
    <>
      {!loading ? (
        <div
          className={`z-50 dark:border dark:border-neutral-700 p-6 sm:rounded-2xl shadow-xl bg-neutral-900 text-white fixed right-0 sm:right-4 bottom-0 sm:bottom-4 w-full sm:max-w-md ${
            hidden ? "hidden" : null
          }`}
        >
          <button
            onClick={() => handleClose()}
            className="absolute top-2 right-2"
          >
            <Close />
          </button>
          <p className="font-medium tracking-tight text-2xl mb-2">
            Become a smarter collector
          </p>
          <p>
            Join the Art Blocks mailing list to get the latest features, updates
            and insights you can use to take your collecting and investing to
            the next level.
          </p>
          <div className="mt-4">
            {success ? (
              <p className="mt-2 text-green-300">
                You{"'"}re in! New features, tips and insights coming your way
              </p>
            ) : (
              <>
                <input
                  placeholder="Email Address"
                  type="email"
                  value={input}
                  onChange={(e: any) => setInput(e.target.value)}
                  className="bg-[rgba(255,255,255,.2)] rounded-xl placeholder-neutral-300 p-3 w-full"
                />
                <button
                  onClick={() => handleSubmission()}
                  className="hover:bg-neutral-200 font-medium bg-white text-neutral-900 block w-full mt-3 rounded-xl p-3"
                >
                  Send me updates
                </button>
              </>
            )}
          </div>
          {error ? (
            <p className="text-sm mt-2 text-red-300">
              Oops! Something went wrong. Please try again.
            </p>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default NewsletterBox;
