import { createConfig } from "@privy-io/wagmi";
import { mainnet, sepolia } from "viem/chains";
import { http } from "wagmi";

import { getRpcUrl } from "@/client/lib/web3Rpc";
import { isTestnet } from "@/shared/config";

const config = createConfig({
  chains: isTestnet() ? [sepolia] : [mainnet],
  ssr: true,
  transports: {
    [mainnet.id]: http(getRpcUrl(mainnet.id)),
    [sepolia.id]: http(getRpcUrl(sepolia.id)),
  },
});

export { config };
