import { LoginButton } from "@/client/components/common/LoginButton";
import { useAuth } from "@/client/components/common/AuthProvider";
import { AccountMenu } from "./AccountMenu";

type Account = {
  menuType?: "default" | "mobile";
};

export const Account = ({ menuType = "default" }: Account) => {
  const { isReady, isAuthenticated, address } = useAuth();

  return (
    <div
      {...(!isReady && {
        "aria-hidden": true,
        style: {
          opacity: 0,
          pointerEvents: "none",
          userSelect: "none",
        },
      })}
      className="flex flex-col gap-2"
    >
      {(() => {
        if (isAuthenticated && address) {
          return <AccountMenu address={address} menuType={menuType} />;
        } else {
          return <LoginButton />;
        }
      })()}
    </div>
  );
};
