import { useEffect } from "react";
import { ActorRef } from "xstate";
import { ArtBlocksClient } from "@artblocks/sdk";
import { useArtBlocksClient } from "../common/ArtBlocksProvider";

export type ArtBlocksClientUpdatedEvent = {
  type: "ART_BLOCKS_CLIENT_UPDATED";
  artblocksClient: ArtBlocksClient;
};

export function useSyncMachineWithArtBlocksClient(
  machineRef: ActorRef<any, ArtBlocksClientUpdatedEvent, any>
) {
  const artblocksClient = useArtBlocksClient();

  useEffect(() => {
    machineRef.send({
      type: "ART_BLOCKS_CLIENT_UPDATED",
      artblocksClient: artblocksClient,
    });
  }, [machineRef, artblocksClient]);
}
