import { Search as SearchIcon } from "../../../icons/search";

interface SearchBar {
  query: string;
  setQuery: any;
  active: boolean;
  onFocus?: any;
  onBlur?: any;
  inputRef?: any;
}

const SearchBar = ({
  query,
  setQuery,
  active = false,
  onFocus,
  onBlur,
  inputRef,
}: SearchBar) => {
  let activeParent = `bg-[rgba(255,255,255,.5)] border-[rgba(0,0,0,.2)] dark:border-[rgba(255,255,255,.2)] dark:bg-[rgba(255,255,255,.2)]`;
  let inactiveParent = `bg-[rgba(255,255,255,.3)] border-[rgba(0,0,0,.1)] dark:border-[rgba(255,255,255,.2)] dark:bg-[rgba(255,255,255,.1)]`;

  return (
    <div
      className={`${
        active ? activeParent : inactiveParent
      } border h-10 rounded-lg flex items-center transition`}
    >
      <div className="h-full w-8 flex-shrink-0 flex flex-col items-end justify-center">
        <SearchIcon
          className={`${
            active
              ? "text-neutral-900 dark:text-white"
              : "text-neutral-500 dark:text-neutral-400"
          } flex-shrink-0 transition`}
        />
      </div>

      <div className="flex flex-grow">
        <input
          className="bg-transparent h-full px-2 w-full dark:text-white placeholder:text-neutral-500 dark:placeholder:text-neutral-400"
          placeholder="Search"
          onFocus={onFocus}
          onBlur={onBlur}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          ref={inputRef}
        ></input>
      </div>

      {!active ? (
        <div className="flex-shrink-0 h-full w-10 flex flex-col items-center justify-center">
          <div
            style={{ fontSize: 12, lineHeight: 5 }}
            className="flex-shrink-0 text-[rgba(0,0,0,.3)] dark:text-[rgba(255,255,255,.4)] font-medium bg-[rgba(0,0,0,.05)] dark:bg-[rgba(255,255,255,.05)] w-6 h-6 flex items-center justify-center rounded-md"
          >
            /
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchBar;
