import { isTestnet } from "./config";

const AUCTION_PLATFORM_FEE_BPS = 0; // 0% auction fee;
const MARKETPLACE_FEE_BPS = 0; // 0% market fee

export const getMarketplaceAuctionFeeBps = () => {
  return AUCTION_PLATFORM_FEE_BPS;
};

export const getMarketplaceListingFeeBps = () => {
  return MARKETPLACE_FEE_BPS;
};

export const getMarketplaceListingFeeDecimalPercent = () => {
  return MARKETPLACE_FEE_BPS / 10000;
};

export const getMarketplaceListingFeePercent = () => {
  return getMarketplaceListingFeeDecimalPercent() * 100;
};

export const getMarketplaceAuctionFeeAddress = () => {
  return isTestnet()
    ? process.env.NEXT_PUBLIC_AUCTION_FEE_ADDRESS_TESTNET
    : process.env.NEXT_PUBLIC_AUCTION_FEE_ADDRESS;
};

export const getMarketplaceListingFeeAddress = () => {
  return isTestnet()
    ? process.env.NEXT_PUBLIC_MARKETPLACE_FEE_ADDRESS_TESTNET
    : process.env.NEXT_PUBLIC_MARKETPLACE_FEE_ADDRESS;
};
