import { ReservoirKitProvider } from "@reservoir0x/reservoir-kit-ui";
export * from "shared/reservoir";

import { PROXY_PATH, RESEVOIR_CLIENT_OPTIONS } from "shared/reservoir";

const RESEVOIR_KIT_OPTIONS = {
  ...RESEVOIR_CLIENT_OPTIONS,
  disablePoweredByReservoir: true,
};

export const ReservoirProvider = ({ baseUrl, children }: any) => {
  const reservoirOptions = {
    ...RESEVOIR_KIT_OPTIONS,
  };

  // if we have a proxy path then update the api url
  if (baseUrl && PROXY_PATH) {
    reservoirOptions.chains = reservoirOptions.chains.map((chainData) => {
      return {
        ...chainData,
        baseApiUrl: `${baseUrl}${PROXY_PATH}`,
      };
    });
  }

  return (
    <ReservoirKitProvider options={{ ...reservoirOptions }}>
      {children}
    </ReservoirKitProvider>
  );
};
