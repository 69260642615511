import { Spinner, Props } from "@/client/components/icons/spinner";
import { cn } from "@/client/lib/classnames";

export function AnimatedSpinner({ className, ...props }: Props) {
  return (
    <Spinner
      className={cn(
        `animate-spin text-neutral-600 dark:text-neutral-200`,
        className
      )}
      {...props}
    />
  );
}
