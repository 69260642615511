import { useAuth } from "@/client/components/common/AuthProvider";
import Button from "@/client/components/frame-design-system/buttons/Button";

export const LoginButton = ({
  className,
  size,
}: {
  className?: string;
  size?: "medium" | "large";
}) => {
  const { isAuthenticated, isReady, login } = useAuth();

  // Disable login when not ready or the user is already authenticated
  const disableLogin = !isReady || (isReady && isAuthenticated);

  return (
    <Button
      variant={disableLogin ? "inactive" : "secondary"}
      disabled={disableLogin}
      onClick={login}
      size={size}
      className={className}
    >
      Connect Wallet
    </Button>
  );
};
