import { IMAGE_CDN_V2_BASE_URL } from "@/shared/images";
import { createBrowserInspector as statelyCreateBrowserInspector } from "@statelyai/inspect";
import { ImageLoaderProps } from "next/image";
import { getCollectionUrl } from "@/client/lib/links";
import { RectReadOnly } from "react-use-measure";

export function numberWithCommas(x: number) {
  if (!x) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatWalletShort(address: string) {
  if (!address) {
    return address;
  }
  let username = address.slice(2, 8);
  return username.toUpperCase();
}

const isoTimestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

function isValidISOTimestamp(timestamp: string) {
  // Validate the ISO string against the pattern
  if (!isoTimestampRegex.test(timestamp)) {
    console.warn(
      `Provided string ${timestamp} is not in valid ISO 8601 format.`
    );
    return false;
  }
  return true;
}

//ex: "2024-01-10T19:30:00.000Z" switches to "20240110T193000Z"
export function convertToGoogleCalendarDateFormat(isoString: string) {
  if (!isValidISOTimestamp(isoString)) {
    return "";
  }

  const date = new Date(Date.parse(isoString));
  const year = date.getUTCFullYear().toString();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  return year + month + day + "T" + hours + minutes + seconds + "Z";
}

export function addMinutesToISOString(isoString: string, minutesToAdd: number) {
  if (!isValidISOTimestamp(isoString)) {
    return "";
  }

  const date = new Date(isoString);
  const newDate = new Date(date.getTime() + minutesToAdd * 60000);
  const isoDate = newDate.toISOString();
  return isoDate;
}

export const formatGoogleCalendarLink = (
  projectName: string,
  artistName: string,
  startsAt: string,
  slugOrId: string
) => {
  const eventStart = convertToGoogleCalendarDateFormat(startsAt);
  const eventEnd = convertToGoogleCalendarDateFormat(
    addMinutesToISOString(startsAt, 30)
  );
  const eventTitle = encodeURIComponent(
    `Drop: ${projectName} by ${artistName} - Art Blocks`
  );

  const eventLocation = encodeURIComponent(getCollectionUrl(slugOrId || ""));
  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${eventStart}/${eventEnd}&location=${eventLocation}`;
};

const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
} as any;

const rtf = new Intl.RelativeTimeFormat("en", {
  numeric: "auto",
});

export function getRelativeTime(d1: Date, d2 = new Date()) {
  if (!d1?.getTime || !d2?.getTime) return "";
  const elapsed = d1.getTime() - d2.getTime();

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (var u in units)
    if (Math.abs(elapsed) > units[u] || u == "second")
      return rtf.format(Math.round(elapsed / units[u]), u as any);
}

export function shortenAssetName(input: string): string {
  const regex = /#\d+/g;
  const hashtags = input.match(regex);
  return hashtags ? hashtags.join("") : input;
}

export const eventTypeToLabel = (
  type: string,
  isToWalletAddress?: boolean,
  isFirstPerson?: boolean
) => {
  switch (type?.toLowerCase()) {
    case "ask":
      return "Listed";
    case "bid":
      return "Placed Offer";
    case "sale":
      if (isToWalletAddress) return "Bought";
      if (!isFirstPerson) return "Sale";
      return "Sold";
    case "transfer":
      return "Transferred";
    case "ask_cancel":
      return "Cancelled Listing";
    case "mint":
      return "Minted";
    default:
      return type;
  }
};

export const formatPrettyWebsite = (website: string) => {
  let replaceHttp = website?.replace("https://", "").replace(/\/+$/, "");
  return replaceHttp?.replace("www.", "");
};

export function cloudinaryLoader(
  disableAutoFormat: boolean,
  { src, width, quality }: ImageLoaderProps
): string {
  const params = [
    "f_auto",
    "c_limit",
    "w_" + width,
    "q_" + (quality || "auto"),
  ];
  if (disableAutoFormat) {
    params.shift();
  }
  const paramsString = params.join(",") + "/";

  return `${IMAGE_CDN_V2_BASE_URL}${paramsString}${src}`;
}

/**
 * Wraps the `createBrowserInspector` function from `@statelyai/inspect` to
 * provide a customized initialization process. This function automatically sets
 * the `autoStart` option based on the `NEXT_PUBLIC_ENABLE_STATELY_INSPECTOR`
 * environment variable, allowing for easy configuration of the inspector's
 * auto-start behavior. Additionally, it ensures compatibility with server-side
 * rendering (SSR) environments by returning an empty object when not running in
 * a browser environment. This prevents warnings or errors that would otherwise
 * be emitted by the Stately inspector when initialized server-side.
 *
 * @param options - Optional configuration options for the Stately inspector.
 * These options will be merged with the default `autoStart` behavior defined by
 * the environment variable.
 * @returns An instance of the Stately inspector if in a browser environment and
 * configured to start, otherwise an empty object to safely handle SSR
 * scenarios.
 */
export function createBrowserInspector(
  options?: Parameters<typeof statelyCreateBrowserInspector>[0]
): ReturnType<typeof statelyCreateBrowserInspector> | Record<string, never> {
  if (typeof window === "undefined") {
    return {};
  }

  const opts = {
    autoStart: process.env.NEXT_PUBLIC_ENABLE_STATELY_INSPECTOR === "true",
    ...options,
  };

  return statelyCreateBrowserInspector({ ...opts });
}

// The round parameter should be true when using this function to pick an iframe/canvas size for
// displaying a script. This is important because when a script is displayed on a fractional canvas
// it will appear blurry.
export const getAspectWidthAndHeight = (
  frameBounds: RectReadOnly,
  currAspectRatio: number,
  isFullScreen: boolean,
  round: boolean
): [number, number] => {
  const frameAspectRatio =
    frameBounds.height > 0 ? frameBounds.width / frameBounds.height : 1;
  let finalWidth, finalHeight;
  if (isFullScreen) {
    finalHeight = frameBounds.height;
    finalWidth = frameBounds.width;
  } else if (frameAspectRatio > currAspectRatio) {
    finalHeight = frameBounds.height;
    finalWidth = frameBounds.height * currAspectRatio;
  } else {
    finalWidth = frameBounds.width;
    finalHeight = frameBounds.width / currAspectRatio;
  }

  if (round) {
    return [Math.round(finalWidth), Math.round(finalHeight)];
  }
  return [finalWidth, finalHeight];
};
