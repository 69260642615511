import { useState, useEffect } from "react";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { useRouter } from "next/router";

import DropdownMenu from "@/client/components/frame-design-system/menus/DropdownMenu";
import Dropdown from "@/client/components/frame-design-system/menus/Dropdown";
import SearchIcon from "@/client/components/icons/search";
import Search from "@/client/components/common/Search";
import Menu from "@/client/components/icons/menu";
import Logo from "@/client/components/common/Logo";
import { Close } from "@/client/components/icons/close";
import Account from "./Account";

interface NavigationHeader {
  darkMode: boolean;
  setDarkMode: any;
  showMobileMenu: any;
  constrainMaxWidth?: boolean;
}

export const NavigationHeader = ({
  darkMode,
  setDarkMode,
  showMobileMenu,
  constrainMaxWidth = false,
}: NavigationHeader) => {
  const router = useRouter();
  const [statsOpen, setStatsStateOpen] = useState<boolean>(false);
  const [exploreOpen, setExploreStateOpen] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const setStatsOpen = (state: boolean) => {
    setStatsStateOpen(state);
    setExploreStateOpen(false);
  };

  const setExploreOpen = (state: boolean) => {
    setExploreStateOpen(state);
    setStatsStateOpen(false);
  };

  const statsDropdownItems = {
    items: [
      { title: "Rankings", link: "/rankings" },
      { title: "Activity", link: "/activity" },
    ],
  };

  const exploreDropdownItems = {
    items: [
      { title: "Collections", link: "/explore" },
      { title: "Upcoming", link: "/upcoming" },
      { title: "Auctions", link: "/auctions" },
    ],
  };

  useEffect(() => {
    setExploreStateOpen(false);
    setStatsOpen(false);
  }, [router.pathname]);

  const renderMobileHeader = () => {
    return (
      <>
        {showSearch ? (
          <div className="w-full h-full lg:hidden flex items-center gap-4">
            <div className="flex-grow relative">
              <Search />
            </div>
            <button onClick={() => setShowSearch(false)}>
              <Close className="w-8 h-8 dark:text-white" />
            </button>
          </div>
        ) : (
          <div className="w-full h-full lg:hidden flex items-center justify-between">
            <button onClick={() => showMobileMenu()}>
              <Menu className="h-8 w-8 dark:text-white" />
            </button>
            <Logo />
            <button onClick={() => setShowSearch(true)}>
              <SearchIcon className="h-8 w-8 dark:text-white" />
            </button>
          </div>
        )}
      </>
    );
  };

  const renderDesktopHeader = () => {
    return (
      <div className="w-full h-full hidden lg:flex items-center justify-between">
        <div className="flex items-center gap-6">
          <Logo />
          <div className="w-64 relative">
            <Search />
          </div>
        </div>

        <nav className="flex items-center gap-4">
          <div className="flex items-center gap-6">
            <div className="relative">
              <Dropdown
                open={exploreOpen}
                setOpen={setExploreOpen}
                title="Explore"
                color="secondary"
              />
              <div
                className={`${
                  exploreOpen
                    ? "opacity-100"
                    : "opacity-0 translate-y-2 invisible"
                } absolute transform transition top-10 right-0 w-44`}
              >
                <DropdownMenu {...exploreDropdownItems} />
              </div>
            </div>

            <div className="relative">
              <Dropdown
                open={statsOpen}
                setOpen={setStatsOpen}
                title="Stats"
                color="secondary"
              />
              <div
                className={`${
                  statsOpen
                    ? "opacity-100"
                    : "opacity-0 translate-y-2 invisible"
                } absolute transform transition top-10 right-0 w-44`}
              >
                <DropdownMenu {...statsDropdownItems} />
              </div>
            </div>
            <Link
              href="/studio/apply"
              className="-ml-1 p-m font-medium text-neutral-600 dark:text-neutral-200 hover:opacity-70"
            >
              Apply
            </Link>
            <Link
              href="/faq"
              className="-ml-1 p-m font-medium text-neutral-600 dark:text-neutral-200 hover:opacity-70"
            >
              FAQ
            </Link>

            <button
              className={`w-6 h-6 hover:opacity-70 transition flex flex-col items-center justify-center rounded-full`}
              onClick={() => setDarkMode((prev: boolean) => !prev)}
            >
              {darkMode ? (
                <SunIcon className={`text-[rgba(255,255,255,.8)] w-5 h-5`} />
              ) : (
                <MoonIcon className={`w-5 h-5 text-neutral-600`} />
              )}
            </button>
          </div>
          <Account />
        </nav>
      </div>
    );
  };

  return (
    <header className="h-20 w-full bg-white dark:bg-neutral-900 border-b dark:border-neutral-800">
      <div
        className={`mx-auto px-8 ${
          constrainMaxWidth ? "2xl:container 2xl:px-0" : null
        } h-full`}
      >
        {renderDesktopHeader()}
        {renderMobileHeader()}
      </div>
    </header>
  );
};
