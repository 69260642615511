import { getBaseUrl } from "@/client/lib/links";
import Head from "next/head";

export const ReservoirMeta = () => {
  return (
    <Head>
      <meta property="reservoir:title" content="Sansa" />
      <meta
        property="reservoir:icon"
        content={`${getBaseUrl()}/market-icon.svg`}
      />
      <meta
        property="reservoir:token-url-mainnet"
        content={getBaseUrl() + "/asset/${contract}/${tokenId}"}
      />
    </Head>
  );
};

export default ReservoirMeta;
