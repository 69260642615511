import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "@privy-io/wagmi";

import { ReservoirProvider } from "@/client/lib/reservoir";
import { config } from "@/client/lib/web3Client";
import { PrivyProvider } from "@/client/lib/privy";
import { ReactNode } from "react";

const queryClient = new QueryClient();

type Props = {
  children: ReactNode;
  baseUrl?: string;
};

export default function Web3Provider({ baseUrl, children }: Props) {
  return (
    <ReservoirProvider baseUrl={baseUrl}>
      <PrivyProvider>
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={config} reconnectOnMount={false}>
            {children}
          </WagmiProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </ReservoirProvider>
  );
}
