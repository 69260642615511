import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Alert = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 15H12.01"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41136 10.4033L10.4033 5.41136C11.2852 4.52955 12.7148 4.52955 13.5967 5.41136L18.5886 10.4033C19.4705 11.2852 19.4705 12.7148 18.5886 13.5967L13.5967 18.5886C12.7148 19.4705 11.2852 19.4705 10.4033 18.5886L5.41136 13.5967C4.52955 12.7148 4.52955 11.2852 5.41136 10.4033Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.75V12.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
