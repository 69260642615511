import { cn } from "@/client/lib/classnames";
import Link from "next/link";

interface DropdownMenuRow {
  type?: "link" | "button";
  title: string;
  external?: boolean;
  link?: string;
  iconLeading?: any;
  iconTrailing?: any;
  onClick?: () => void;
  onClose?: () => void;
  className?: string;
}

const DropdownMenuRow = ({
  type = "link",
  title,
  external = false,
  link,
  iconLeading,
  iconTrailing,
  onClick,
  onClose,
  className,
}: DropdownMenuRow) => {
  const renderType = () => {
    if (type === "link" && link && !external) {
      return (
        (<Link
          href={link}
          className={cn(`flex items-center justify-between`, className)}>

          <div className="flex items-center gap-1">
            {iconLeading ? iconLeading : null}
            {title}
          </div>
          {iconTrailing ? iconTrailing : null}

        </Link>)
      );
    } else if (type === "button" && onClick) {
      return (
        <button
          className={cn(`flex w-full items-center justify-between`, className)}
          onClick={() => {
            onClick?.();
            onClose?.();
          }}
        >
          <div className="flex items-center gap-1">
            {iconLeading ? iconLeading : null}
            {title}
          </div>
          {iconTrailing ? iconTrailing : null}
        </button>
      );
    } else return;
  };

  return (
    <div className="p-2 transition rounded-md cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:text-white">
      {renderType()}
    </div>
  );
};

export default DropdownMenuRow;
