import { purchaseTrackingManagerMachineContext } from ".";
import { createBrowserInspector } from "@/client/lib/helpers";
import { useSyncMachineWithArtBlocksClient } from "@/client/components/hooks/useSyncMachineWithWeb3Provider";
import { ActorRefFrom, SnapshotFrom } from "xstate";
import { purchaseTrackingMachine } from "@artblocks/sdk/dist/machines/purchase-tracking-machine";
import { ReactNode, useEffect } from "react";
import { ToastOptions, toast } from "react-hot-toast";
import { triggerToast } from "../toast";
import { getAssetPath, getEtherscanTxUrl } from "@/client/lib/links";
import { External } from "../../icons/external";
import Link from "next/link";
import { useArtBlocksClient } from "../ArtBlocksProvider";
import { getBasePath } from "@/shared/config";

const { inspect } = createBrowserInspector();

export function PurchaseTrackingManagerMachineProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const artblocksClient = useArtBlocksClient();

  return (
    <purchaseTrackingManagerMachineContext.Provider
      options={{
        input: { artblocksClient, marketplaceUrl: getBasePath() },
        inspect,
      }}
    >
      <PurchaseTrackingManagerMachineManager />
      {children}
    </purchaseTrackingManagerMachineContext.Provider>
  );
}

function PurchaseTrackingManagerMachineManager() {
  const managerMachineRef = purchaseTrackingManagerMachineContext.useActorRef();
  useSyncMachineWithArtBlocksClient(managerMachineRef);

  return null;
}

function PurchaseTrackingNotifier({
  purchaseTrackingMachineRef: purchaseTrackingMachineRef,
}: {
  purchaseTrackingMachineRef: ActorRefFrom<typeof purchaseTrackingMachine>;
}) {
  useEffect(() => {
    let prevToastId: string | undefined;
    let prevSnapshotValue: string | undefined; // Renamed for consistency

    // Function to handle snapshot changes and manage toasts accordingly
    const handleSnapshotChange = (
      currentSnapshot: SnapshotFrom<typeof purchaseTrackingMachine> // Renamed for clarity
    ) => {
      const currentSnapshotValue = currentSnapshot.value.toString();

      // Dismiss the previous toast if the snapshot value has changed
      if (
        prevSnapshotValue &&
        prevSnapshotValue !== currentSnapshotValue &&
        prevToastId
      ) {
        toast.dismiss(prevToastId);
      }
      prevSnapshotValue = currentSnapshotValue; // Update the previous snapshot value

      // Trigger toasts based on the current snapshot of the machine
      prevToastId = triggerToastForSnapshot(
        purchaseTrackingMachineRef.id,
        currentSnapshot
      ); // Renamed function for consistency
    };

    // Trigger the initial toast if applicable
    const initialSnapshot = purchaseTrackingMachineRef.getSnapshot();
    prevSnapshotValue = initialSnapshot.value.toString();
    prevToastId = triggerToastForSnapshot(
      purchaseTrackingMachineRef.id,
      initialSnapshot
    );

    const subscription =
      purchaseTrackingMachineRef.subscribe(handleSnapshotChange); // Updated to use the renamed function

    return () => {
      subscription.unsubscribe();
      if (prevToastId) {
        toast.dismiss(prevToastId);
      }
    };
  }, [purchaseTrackingMachineRef]);

  return null;
}

// Function to trigger toasts based on the machine snapshot
function triggerToastForSnapshot(
  machineId: string,
  snapshot: SnapshotFrom<typeof purchaseTrackingMachine>
) {
  const baseToastOptions: ToastOptions = {
    duration: Infinity,
    id: `${machineId}:${snapshot.value}`,
    position: "bottom-right",
  };

  const txUrl = getEtherscanTxUrl(snapshot.context.purchaseTransactionHash);
  const shortenedTxHash = snapshot.context.purchaseTransactionHash.slice(0, 6);
  if (snapshot.matches("awaitingPurchaseConfirmation")) {
    return triggerToast({
      type: "loading",
      message: "Waiting for purchase confirmation",
      submessage: (
        <span className="flex items-center space-x-1">
          <span>This may take a few moments</span>
          <a
            href={txUrl}
            target="_blank"
            className="flex items-center text-black text-opacity-100 dark:text-white"
          >
            (tx: {shortenedTxHash} <External size={16} />)
          </a>
        </span>
      ),
      options: baseToastOptions,
    });
  } else if (snapshot.matches("awaitingTokenSync")) {
    const tokenId = snapshot.context.mintedTokenId?.split("-")[1];
    return triggerToast({
      type: "loading",
      message: `Waiting for token ${
        tokenId ? `#${tokenId}` : ""
      } to be indexed`,
      submessage: (
        <span className="flex items-center space-x-1">
          <span>This may take a few moments</span>
          <a
            href={txUrl}
            target="_blank"
            className="flex items-center text-black text-opacity-100 dark:text-white"
          >
            (tx: {shortenedTxHash} <External size={16} />)
          </a>
        </span>
      ),
      options: baseToastOptions,
    });
  } else if (snapshot.matches("tokenReady")) {
    const mintedToken = snapshot.context.mintedToken;
    let submessage: string | ReactNode = "Your token is ready";
    if (mintedToken) {
      const projectName = mintedToken.project.name;
      const tokenInvocation = mintedToken.invocation;
      const tokenId = mintedToken.token_id;
      const contractAddress = mintedToken.contract_address;
      const assetUrl = getAssetPath(contractAddress, tokenId);

      submessage = (
        <span>
          <Link href={`${assetUrl}?generator=true`}>

            <span className="text-black dark:text-white">
              You purchased {projectName} #{tokenInvocation}
            </span>
            <br />
            <span>Click to visit the asset page</span>

          </Link>
          <br />
        </span>
      );
    }

    return triggerToast({
      type: "success",
      message: "Purchase Successful!",
      submessage,
      options: {
        ...baseToastOptions,
        duration: 20000,
      },
    });
  } else if (snapshot.matches("error")) {
    return triggerToast({
      type: "error",
      message: "Oops something went wrong",
      submessage: snapshot.context.errorMessage ?? "An unknown error occurred",
      options: baseToastOptions,
    });
  }
}
