import { useEffect } from "react";
import { useAuth } from "@/client/components/common/AuthProvider";

import { isBrowser } from "shared/config";
import analytics from "client/lib/analytics";

export const AuthWatcher = () => {
  const { address } = useAuth();
  useEffect(() => {
    if (address) {
      analyticsIdentifyUser({ id: address });
    }
  }, [address]);

  return null;
};

const analyticsIdentifyUser = (user: { id: string }) => {
  if (isBrowser()) {
    analytics.identify(user);
  }
};

export default AuthWatcher;
