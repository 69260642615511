import { Spinner as SpinnerIcon } from "../../icons/spinner";

interface Loader {
  size?: "xs" | "lg";
  label?: string;
  alignment?: "center" | "left";
}

const Loader = ({ size = "xs", label, alignment = "center" }: Loader) => {
  const getSize = () => {
    switch (size) {
      case "xs":
        return "h-6 w-6";

      case "lg":
        return "h-16 w-16";
    }
  };

  const getAlignment = () => {
    switch (alignment) {
      case "center":
        return "items-center";
      case "left":
        return "items-start";
    }
  };

  return (
    <div className={`flex px-2 flex-col ${getAlignment()}`}>
      <SpinnerIcon
        className={`${getSize()} translate animate-spin text-neutral-400 dark:text-neutral-600`}
      />
      {label ? (
        <p className="text-p-xs text-neutral-400 dark:text-neutral-600">
          {label}
        </p>
      ) : null}
    </div>
  );
};

export default Loader;
