import { getBasePath } from "@/shared/config";

interface Avatar {
  rounding?: "normal" | "full";
  size?: "24" | "32" | "44" | "56" | "64" | "96";
  imageUrl?: string;
  noBackground?: boolean;
  bgContain?: boolean;
}

const Avatar = ({
  size = "24",
  rounding = "normal",
  imageUrl,
  noBackground = false,
  bgContain = false,
}: Avatar) => {
  const getSize = () => {
    switch (size) {
      case "24":
        return "h-6 w-6";
      case "32":
        return "h-8 w-8";
      case "44":
        return "h-11 w-11";
      case "56":
        return "h-14 w-14";
      case "64":
        return "h-16 w-16";
      case "96":
        return "h-24 w-24";
    }
  };

  const getRounding = () => {
    switch (rounding) {
      case "normal":
        return "rounded-md";
      case "full":
        return "rounded-full";
    }
  };

  const placeholderUrl = `${getBasePath()}/default-avatar.png`;
  let image = imageUrl ? imageUrl : placeholderUrl;

  return (
    <div
      className={`${getSize()} ${getRounding()} ${
        noBackground ? null : "bg-neutral-200"
      } overflow-hidden flex-shrink-0`}
    >
      <div
        style={{ backgroundImage: `url(${image})` }}
        className={`${getSize()} ${
          bgContain ? "bg-contain" : "bg-cover"
        } bg-center bg-no-repeat`}
      ></div>
    </div>
  );
};

export default Avatar;
