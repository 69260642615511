import * as React from "react";

export interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Eth = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.56641 4.55935L2.76099 0L0 4.56239L2.78244 6.22185L5.56641 4.55935Z"
        fill="currentColor"
      />
      <path
        d="M5.56641 5.11627L2.77631 6.74082L0 5.11627L2.78244 8.99999L5.56641 5.11627Z"
        fill="currentColor"
      />
    </svg>
  );
};
