/**
 * If the enviroment is production
 */
export const isProduction = () => {
  return getEnv() === "production";
};

/**
 * If the build type is production (ex: not running via `yarn dev`)
 */
export const isProductionBuild = () => {
  return process.env.NODE_ENV === "production";
};

/**
 * If the build type is dev (ex: running locally via `yarn dev`)
 */
export const isDevBuild = () => {
  return process.env.NODE_ENV === "development";
};

export const isBrowser = () => {
  return typeof window !== "undefined";
};

export const getEnv = (): "production" | "development" | "test" => {
  const env = process.env.NEXT_PUBLIC_ENV;
  switch (env) {
    case "production":
      return "production";
    case "development":
      return "development";
    case "test":
      return "test";
    default:
      throw new Error(`unknown enviroment ${env} in NEXT_PUBLIC_ENV`);
  }
};

export const getNetwork = (): "mainnet" | "testnet" => {
  return process.env.NEXT_PUBLIC_NETWORK === "testnet" ? "testnet" : "mainnet";
};

export const isTestnet = () => {
  return getNetwork() === "testnet";
};

export const getBasePath = () => {
  return process.env.NEXT_PUBLIC_BASE_PATH || "";
};
