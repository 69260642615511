import Link, { LinkProps } from "next/link";
import React from "react";
import { cn } from "@/client/lib/classnames";
import { ClassValue } from "clsx";
import { Spinner } from "../../icons/spinner";

type CommonButtonProps = {
  size?: "medium" | "large";
  variant?: "primary" | "secondary" | "inactive";
  darkModeDisabled?: boolean;
  disabled?: boolean;
};

type AsButtonProps = {
  as?: "button";
} & React.ComponentProps<"button">;
type AsAnchorProps = { as: "a" } & React.ComponentProps<"a">;
type AsLinkProps = { as: "Link" } & Omit<LinkProps, "as"> &
  Omit<React.ComponentProps<"a">, "href">;

type ButtonProps = CommonButtonProps &
  (AsButtonProps | AsAnchorProps | AsLinkProps);

const Button = ({
  size = "medium",
  variant = "primary",
  className,
  darkModeDisabled,
  disabled,
  ...props
}: ButtonProps) => {
  const commonClassNames: ClassValue = "flex justify-center items-center gap-1";

  let sizeClassNames: ClassValue;
  switch (size) {
    case "medium":
      sizeClassNames = "h-10 text-p-xs md:text-p-m font-medium px-5 rounded-lg";
      break;
    case "large":
      sizeClassNames =
        "h-16 text-p-xs md:text-p-xl font-medium px-6 rounded-xl";
      break;
  }

  let variantClassNames: ClassValue;
  switch (variant) {
    case "primary":
      variantClassNames = `bg-neutral-900 text-white shadow-sm hover:bg-neutral-800 ${
        darkModeDisabled
          ? ""
          : "dark:bg-white dark:text-neutral-900 hover:dark:bg-neutral-200"
      }`;
      break;
    case "secondary":
      variantClassNames = `border border-neutral-200 bg-white hover:bg-neutral-100 text-neutral-900 shadow-sm ${
        darkModeDisabled
          ? ""
          : "dark:border-neutral-700 dark:hover:bg-neutral-800 dark:text-white dark:bg-neutral-900"
      }`;
      break;
    case "inactive":
      variantClassNames = `bg-neutral-200 text-neutral-500 cursor-not-allowed ${
        darkModeDisabled ? "" : "dark:bg-neutral-800"
      }`;
      break;
  }

  if (props.as === "Link") {
    // Break out the props that are specific to the Link component and pass the
    // rest to the child anchor element
    const {
      href,
      as,
      replace,
      scroll,
      shallow,
      passHref,
      prefetch,
      locale,
      legacyBehavior,
      onMouseEnter,
      onTouchStart,
      onClick,
      ...rest
    } = props;

    return (
      <Link
        {...{
          href,
          replace,
          scroll,
          shallow,
          passHref,
          prefetch,
          locale,
          legacyBehavior,
          onMouseEnter,
          onTouchStart,
          onClick,
        }}
        className={cn(
          commonClassNames,
          sizeClassNames,
          variantClassNames,
          className
        )}
      >
        {props.children}
      </Link>
    );
  }

  if (props.as === "a") {
    return (
      <a
        className={cn(
          commonClassNames,
          sizeClassNames,
          variantClassNames,
          className
        )}
        {...props}
      >
        {props.children}
      </a>
    );
  }

  return (
    <button
      className={cn(
        commonClassNames,
        sizeClassNames,
        variantClassNames,
        className
      )}
      disabled={disabled}
      {...props}
    >
      {props.children}
    </button>
  );
};

export function ButtonLoading() {
  return <Spinner className="animate-spin" />;
}

export default Button;
