import { getBasePath } from "@/shared/config";
import Link from "next/link";

const Logo = () => {
  return (
    (<Link href="/">

      <img
        className="h-6 dark:hidden"
        src={`${getBasePath()}/logo-text.svg`}
      />
      <img
        className="h-6 hidden dark:block"
        src={`${getBasePath()}/logo-text-white.svg`}
      />

    </Link>)
  );
};

export default Logo;
