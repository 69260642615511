import { Chevron } from "../../icons/chevron";
import DropdownMenuRow from "./DropdownMenuRow";

interface DropdownMenu {
  items: DropdownMenuRow[];
  onClose?: () => void;
}

const DropdownMenu = ({ items, onClose }: DropdownMenu) => {
  return (
    <div className="shadow-sm border border-neutral-200 bg-white dark:bg-neutral-900 dark:border-neutral-800 rounded-xl p-2">
      {items.map((row: DropdownMenuRow, idx: number) => {
        return <DropdownMenuRow key={idx} {...row} onClose={onClose} />;
      })}
    </div>
  );
};

export default DropdownMenu;
