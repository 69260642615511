import { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";

import { NavigationHeader } from "./NavigationHeader";
import { MobileMenu } from "./MobileMenu";

interface Navigation {
  darkMode: boolean;
  setDarkMode: (b: boolean) => void;
}

const HIDDEN_HEADER_PATHS = ["/asset/[contract]/[tokenId]/render"];

export const Navigation = ({ darkMode, setDarkMode }: Navigation) => {
  const router = useRouter();

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  const isHome = router.asPath === "/";
  const hideHeader = HIDDEN_HEADER_PATHS.includes(router.pathname);

  useEffect(() => {
    if (showMobileMenu) setShowMobileMenu(false);
  }, [router.pathname]);

  if (hideHeader) return null;
  return (
    <div className="top-0 z-40 sticky">
      <NavigationHeader
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        showMobileMenu={() => setShowMobileMenu(true)}
        constrainMaxWidth={isHome}
      />
      <MobileMenu
        show={showMobileMenu}
        closeMenu={() => setShowMobileMenu(false)}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
    </div>
  );
};
