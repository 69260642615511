import { ReactNode } from "react";
import toast, { Toast } from "react-hot-toast";
import { Close } from "@/client/components/icons/close";
import { Check } from "@/client/components/icons/check";
import { AnimatedSpinner } from "./AnimatedSpinner";
import { cn } from "@/client/lib/classnames";

export function ToastBox({
  type,
  message,
  submessage,
  className,
}: {
  type: "error" | "success" | "loading";
  message: ReactNode;
  submessage?: ReactNode;
  className?: string;
}) {
  const iconMap = {
    error: <Close />,
    success: <Check className="text-green-500" />,
    loading: <AnimatedSpinner size={24} />,
  };

  return (
    <div
      className={cn(
        "flex p-2 border rounded-2xl min-w-[25vw] shadow-lg dark:shadow-white/25",
        {
          "bg-white dark:text-white dark:bg-neutral-900 border-gray-200 dark:border-neutral-800":
            type === "success" || type === "loading",
          "bg-red-100 border-red-200": type === "error",
        },
        className
      )}
    >
      <div className="mr-2">{iconMap[type]}</div>
      <div>
        <p className="text-p-s">{message}</p>
        {submessage ? (
          <p className="text-black dark:text-white text-p-xs text-opacity-60 dark:text-opacity-60">
            {submessage}
          </p>
        ) : null}
      </div>
    </div>
  );
}

type TriggerToastParams = {
  type: "error" | "success" | "loading";
  message: string | ReactNode;
  submessage?: string | ReactNode;
  options?: Partial<
    Pick<
      Toast,
      | "id"
      | "style"
      | "className"
      | "icon"
      | "duration"
      | "ariaProps"
      | "position"
      | "iconTheme"
    >
  >;
};

export function triggerToast({
  type,
  message,
  submessage,
  options,
}: TriggerToastParams) {
  return toast.custom(
    (t) => (
      <ToastBox
        type={type}
        message={message}
        submessage={submessage}
        className={cn({
          "animate-toast-in": t.visible,
          "animate-toast-out": !t.visible,
        })}
      />
    ),
    options
  );
}
