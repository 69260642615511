import { ExcessSettlementFundsManagerMachineContext } from ".";

/**
 * A React hook that determines if the current user has any claimable excess
 * settlement funds.
 *
 * This hook utilizes the `ExcessSettlementFundsManagerMachineContext` to access
 * the state of the Excess Settlement Funds Manager state machine. It checks the
 * `claimMachines` context property, which is expected to be a collection (e.g.,
 * Map or Set) of claim machine instances.
 *
 * @returns A boolean value indicating whether the user has one or
 * more claimable excess settlement funds based on the presence of claim machine
 * instances in the `claimMachines` collection.
 */
export function useUserHasClaimableExcessSettlementFunds() {
  return ExcessSettlementFundsManagerMachineContext.useSelector((state) => {
    return (state.context.claimMachines?.size ?? 0) > 0;
  });
}

/**
 * A React hook that retrieves references to all claim machine instances within
 * the Excess Settlement Funds Manager context.
 *
 * This hook is designed to access the `claimMachines` context property from the
 * `ExcessSettlementFundsManagerMachineContext`. The `claimMachines` is expected
 * to be a collection (e.g., Map) that holds instances of claim machines,
 * each representing a claimable excess settlement fund for the user.
 *
 * The hook uses a custom comparison function to optimize re-renders. It only
 * triggers a re-render if the size of the `claimMachines` collection changes or
 * if there are changes in the keys of the collection, indicating that claim
 * machines have been added or removed. This ensures that components using this
 * hook do not re-render unnecessarily when the underlying claim machine
 * instances remain the same.
 *
 * @returns A collection of claim machine references, allowing consumers of this
 * hook to interact with or display information about the user's claimable
 * excess settlement funds.
 */
export function useExcessSettlementFundsClaimMachineRefs() {
  return ExcessSettlementFundsManagerMachineContext.useSelector(
    (state) => {
      return state.context.claimMachines;
    },
    (a, b) => {
      if (a?.size !== b?.size) return false;
      for (let key of a?.keys() ?? []) {
        if (!b?.has(key)) return false;
      }
      return true;
    }
  );
}
