"use client";

import * as React from "react";
import { createContext } from "react";
import { usePublicClient } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";

import { ArtBlocksClient } from "@artblocks/sdk";
import { useAuthenticatedWalletClient } from "@/client/components/hooks/useAuthenticatedWalletClient";
import { getArtBlocksGraphQLEndpoint } from "@/shared/artblocks";
import { isTestnet } from "@/shared/config";

const ArtBlocksContext = createContext<ArtBlocksClient>(
  new ArtBlocksClient({
    graphqlEndpoint: getArtBlocksGraphQLEndpoint(),
  })
);

interface ArtblocksProviderProps {
  children: React.ReactNode;
}

export function ArtBlocksProvider({ children }: ArtblocksProviderProps) {
  const publicClient = usePublicClient({
    chainId: isTestnet() ? sepolia.id : mainnet.id,
  });
  const walletClient = useAuthenticatedWalletClient();

  const [abClient, setAbClient] = React.useState<ArtBlocksClient>(
    new ArtBlocksClient({
      graphqlEndpoint: getArtBlocksGraphQLEndpoint(),
    })
  );

  React.useEffect(() => {
    setAbClient(
      new ArtBlocksClient({
        graphqlEndpoint: getArtBlocksGraphQLEndpoint(),
        // TODO: Figure out how to deal with version differences
        publicClient: publicClient as any,
        walletClient: walletClient as any,
      })
    );
  }, [publicClient, walletClient]);

  return (
    <ArtBlocksContext.Provider value={abClient}>
      {children}
    </ArtBlocksContext.Provider>
  );
}

export function useArtBlocksClient() {
  const context = React.useContext(ArtBlocksContext);

  if (context === undefined) {
    throw new Error(
      "useArtBlocksClient must be used within an ArtBlocksProvider"
    );
  }

  return context;
}
