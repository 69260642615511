import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Combines class names using `clsx` and merges Tailwind CSS classes with `twMerge`.
 *
 * This utility function is designed to streamline the process of combining and deduplicating
 * class names, especially when working with conditional classes and Tailwind CSS utilities.
 * It leverages `clsx` for flexible class name inputs and `twMerge` to intelligently merge
 * Tailwind CSS classes, avoiding conflicts and redundancy.
 *
 * Code originally taken from: https://ui.shadcn.com/docs/installation/manual
 *
 * @param inputs - A rest parameter that accepts an arbitrary number of class values.
 * @returns A string with combined and deduplicated class names.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
