// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "styles/globals.css";

import { useEffect, useState } from "react";
import type { AppContext, AppProps } from "next/app";
import { default as NextApp } from "next/app";
import { useRouter } from "next/router";

import Web3Provider from "@/client/components/common/Web3Provider";
import analytics from "@/client/lib/analytics";
import Navigation from "@/client/components/common/Navigation";
import ReservoirMeta from "@/client/components/common/ReservoirMeta";
import AuthWatcher from "@/client/components/common/AuthWatcher";
import NewsletterBox from "@/client/components/common/NewsletterBox";
import { getBasePath, isBrowser } from "@/shared/config";
import { ExcessSettlementFundsManagerMachineContextProvider } from "@/client/components/common/ExcessSettlementFundsManagerContext/ExcessSettlementFundsManagerContextProvider";
import { Toaster } from "react-hot-toast";
import { PurchaseTrackingManagerMachineProvider } from "@/client/components/common/PurchaseTrackingManagerMachineContext/PurchaseTrackingManagerMachineProvider";
import { ArtBlocksProvider } from "@/client/components/common/ArtBlocksProvider";
import { AuthProvider } from "@/client/components/common/AuthProvider";

function AppWrapper(props: AppProps & { baseUrl: string }) {
  return <MyApp {...props} />;
}

function MyApp({
  Component,
  pageProps,
  baseUrl,
}: AppProps & { baseUrl: string }) {
  const router = useRouter();

  const [darkMode, setDarkMode] = useState<boolean | any>(null);

  // Analytics
  useEffect(() => {
    // setup analytics on app load
    analytics.init();

    // track initial page load
    trackPageView({
      pathname: router.pathname,
      url: router.asPath,
      query: router.query,
    });

    const handleRouteChangeComplete = (
      url: string,
      options: { shallow: boolean }
    ) => {
      // dont track the page if we are shollow routing
      // most likely we are just updating query params
      if (options.shallow) return;

      trackPageView({
        pathname: router.pathname,
        url: url,
        query: router.query,
      });
    };

    // Record a pageview when route changes
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    // check if they have a theme already set

    // default to no theme
    let defaultDark = false;

    if (
      !("sansaTheme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      // if there's no theme in localstorage and there os prefer dark use it
      defaultDark = true;
    }

    if ("sansaTheme" in localStorage) {
      // if there is a user theme set use it
      defaultDark = localStorage.sansaTheme === "light" ? false : true;
    }

    setDarkMode(defaultDark);
  }, []);

  useEffect(() => {
    // when a user changes darkMode add it to localstorage
    if (darkMode === true) {
      // Whenever the user explicitly chooses light mode
      localStorage.sansaTheme = "dark";
      document.documentElement.classList.add("dark");
    } else if (darkMode === false) {
      // Whenever the user explicitly chooses light mode
      localStorage.sansaTheme = "light";
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <Web3Provider baseUrl={baseUrl}>
      <AuthProvider>
        <ArtBlocksProvider>
          <ExcessSettlementFundsManagerMachineContextProvider>
            <PurchaseTrackingManagerMachineProvider>
              <ReservoirMeta />
              <AuthWatcher />
              <Toaster />
              <div className={`${darkMode ? "dark" : ""}`}>
                <div
                  id="pageRoot"
                  className="w-full h-full dark:bg-neutral-900"
                >
                  <Navigation setDarkMode={setDarkMode} darkMode={darkMode} />
                  <NewsletterBox />
                  <Component {...pageProps} />
                </div>
              </div>
            </PurchaseTrackingManagerMachineProvider>
          </ExcessSettlementFundsManagerMachineContextProvider>
        </ArtBlocksProvider>
      </AuthProvider>
    </Web3Provider>
  );
}

const trackPageView = (data: {
  url: string;
  pathname: string;
  query?: object;
}) => {
  analytics.trackPageView(data);
};

AppWrapper.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await NextApp.getInitialProps(appContext);
  let baseUrl = "";

  if (appContext.ctx.req?.headers.host) {
    // check the x-forwarded-host first in case we are running behind a proxy
    const host =
      appContext.ctx.req?.headers["x-forwarded-host"] ||
      appContext.ctx.req?.headers.host;

    baseUrl = `${host.includes("localhost") ? "http" : "https"}://${host}`;
  } else if (process.env.NEXT_PUBLIC_HOST_URL) {
    baseUrl = process.env.NEXT_PUBLIC_HOST_URL || "";
  } else if (isBrowser()) {
    const host = window.location.host;
    baseUrl = `${host.includes("localhost") ? "http" : "https"}://${host}`;
  }
  // remove any trailing slashes
  baseUrl = baseUrl.replace(/\/$/, "");

  // add basePath if we have one
  baseUrl = getBasePath() ? `${baseUrl}${getBasePath()}` : baseUrl;

  return { ...appProps, baseUrl };
};

export default AppWrapper;
