import { Dialog, Transition } from "@headlessui/react";
import { excessSettlementFundsClaimMachine } from "@artblocks/sdk/dist/machines/excess-settlement-funds-claim-machine";
import { ActorRefFrom } from "xstate";
import { Close } from "../../icons/close";
import { useSelector } from "@xstate/react";
import { formatEther } from "viem";
import { Eth } from "../../icons/eth";
import SimpleButton from "../Button";
import { AnimatedSpinner } from "../AnimatedSpinner";
import { Check } from "../../icons/check";
import { useExcessSettlementFundsClaimMachineRefs } from "../ExcessSettlementFundsManagerContext/hooks";
import Image from "next/image";
import { cloudinaryLoader } from "@/client/lib/helpers";

/**
 * Renders a modal for claiming excess settlement funds from projects where the
 * clearing price was lower than the current user's purchase price.
 *
 * Displays a list of eligible claims, allowing users to review details and
 * initiate the claim process for each. Leverages the
 * `ExcessSettlementFundsManagerMachineContext` to manage state and optimize
 * re-renders based on the set of claim IDs.
 *
 * @param props The component props.
 * @param props.show Determines if the modal is visible.
 * @param props.onClose Callback function for when the modal is requested to be
 * closed.
 */
export const ExcessSettlementFundsClaimModal = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const excessSettlementFundClaimMachineRefs =
    useExcessSettlementFundsClaimMachineRefs();

  const claimMachineEntries = [
    ...(excessSettlementFundClaimMachineRefs?.entries() ?? []),
  ];

  return (
    <>
      <Transition appear show={show}>
        <Dialog className="relative z-50" onClose={onClose}>
          {/* Background overlay */}
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-40 bg-black bg-opacity-25 dark:bg-opacity-75" />
          </Transition.Child>
          <div
            className="fixed inset-0 z-50 overflow-y-auto"
            style={{ zIndex: 1000 }}
          >
            <div className="flex items-center justify-center w-full min-h-full p-4 text-center">
              <Transition.Child
                className="w-full max-w-xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full px-8 py-6 overflow-visible text-left align-middle transition-all transform shadow-xl rounded-3xl dark:bg-neutral-900 dark:text-white bg-neutral-100">
                  <div className="flex items-center justify-between mb-4 rounded-t-3xl">
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        Claim Excess Settlement Funds
                      </Dialog.Title>
                    </div>
                    <button
                      className="flex flex-col items-center justify-center w-8 h-8 rounded-full bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700"
                      onClick={onClose}
                    >
                      <Close />
                    </button>
                  </div>
                  <p className="mb-6">
                    For one or more projects from which you&apos;ve purchased
                    tokens, the clearing price was lower than your purchase
                    price, entitling you to claim the difference. Review the
                    details below and claim your funds.
                  </p>
                  <div className="space-y-4">
                    {claimMachineEntries.map(([receiptId, claimMachine]) => {
                      return (
                        <ExcessSettlementFundClaimItem
                          key={receiptId}
                          claimMachine={claimMachine}
                        />
                      );
                    })}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

/**
 * Renders an individual claim item within the Excess Settlement Funds Claim
 * Modal.
 *
 * This component displays the details of a specific claim, including the
 * project name, the clearing price, and the amount claimable in ETH. It also
 * provides a button to initiate the claim process, with dynamic state updates
 * based on the claim's progress (idle, in progress, success, or error).
 *
 * The component leverages the `useSelector` hook from XState to subscribe to
 * state changes in the provided `claimMachine`, ensuring it re-renders only
 * when necessary to reflect the current state of the claim process.
 *
 * @param props The component props.
 * @param props.claimMachine An ActorRef from the
 * `excessSettlementFundsClaimMachine`, representing the state machine instance
 * for managing the claim process for a specific claim.
 */
function ExcessSettlementFundClaimItem({
  claimMachine,
}: {
  claimMachine: ActorRefFrom<typeof excessSettlementFundsClaimMachine>;
}) {
  const receipt = useSelector(claimMachine, (state) => state.context.receipt);
  const claimState = useSelector(
    claimMachine,
    (state) => state,
    (a, b) => {
      return a.value === b.value;
    }
  );
  const project = receipt.project;
  const projectMinterConfiguration = receipt.project_minter_configuration;
  const featuredToken = project.featured_token?.[0];
  const amount = receipt.excess_settlement_funds;
  const amountInEth = amount
    ? Number(formatEther(BigInt(amount))).toFixed(4)
    : "--";
  const clearingPrice =
    projectMinterConfiguration?.extra_minter_details?.currentSettledPrice;
  const clearingPriceInEth = clearingPrice
    ? Number(formatEther(BigInt(clearingPrice))).toFixed(4)
    : "--";

  return (
    <div className="p-4 sm:flex sm:space-x-4 rounded-md bg-[#F0F0F0] dark:bg-neutral-800">
      <div className="flex items-center mb-4 sm:mb-0">
        <div className="w-12 h-12 mr-4 overflow-hidden rounded-lg sm:mr-0">
          <Image
            src={featuredToken?.media_url ?? ""}
            alt={`Featured token for project ${project.name}`}
            loader={(props) => cloudinaryLoader(false, props)}
            placeholder={"empty"}
            width={48}
            height={48}
            style={{
              maxWidth: "100%",
              height: "auto",
              objectFit: "cover"
            }} />
        </div>
        <div className="flex-1 sm:hidden">
          <p className="text-xs text-gray-400">Project</p>
          <p className="font-medium">{project.name}</p>
        </div>
      </div>
      <div className="flex-1 sm:flex sm:items-center">
        <div className="flex items-center justify-between flex-1 h-full mb-4 sm:mb-0 sm:justify-start sm:mr-8">
          <div className="flex-1 hidden mr-4 sm:block">
            <p className="text-xs text-gray-400">Project</p>
            <p className="font-medium">{project.name}</p>
          </div>
          <div className="mr-4">
            <p className="text-xs text-gray-400">Clearing</p>
            <p className="flex items-center font-medium">
              <Eth size={16} />
              {clearingPriceInEth}
            </p>
          </div>
          <div>
            <p className="text-xs text-gray-400">Claimable</p>
            <p className="flex items-center font-medium">
              <Eth size={16} />
              {amountInEth}
            </p>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="flex-1">
            <SimpleButton
              className="justify-center w-full p-3 text-center sm:w-20"
              disabled={!claimState.matches("idle")}
              onClick={() => {
                claimMachine.send({ type: "INITIATE_CLAIM" });
              }}
            >
              {claimState.matches("idle") ? (
                "Claim"
              ) : claimState.matches("success") ? (
                <Check size={24} />
              ) : (
                <div className="flex flex-col items-center">
                  <AnimatedSpinner
                    className="text-neutral-100 dark:text-neutral-600"
                    size={24}
                  />
                  {claimState.matches("awaitingClaimConfirmations") ? (
                    <p className="text-xs text-neutral-100 dark:text-neutral-600">
                      Confirming
                    </p>
                  ) : claimState.matches("fetchingReceipt") ||
                    claimState.matches("waiting") ? (
                    <p className="text-xs text-neutral-100 dark:text-neutral-600">
                      Indexing
                    </p>
                  ) : null}
                </div>
              )}
            </SimpleButton>
          </div>
        </div>
      </div>
    </div>
  );
}
