import FrameSearchBar from "../frame-design-system/inputs/Search/SearchBar";
import FrameSearchResults from "../frame-design-system/inputs/Search/SearchResults";
import { debounce } from "lodash";
import { search } from "client/lib/api";
import { useRef, useEffect, useState, useMemo } from "react";
import { getBasePath } from "@/shared/config";

const Search = () => {
  let [input, setInput] = useState<string>("");
  let [results, setResults] = useState<any>(null);
  let [loading, setLoading] = useState<boolean>(false);
  let [searchActive, setSearchActive] = useState<boolean>(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!input) return;

    debouncedFetch(input);
  }, [input]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      // check if "/" is pressed
      if (event.key === "/") {
        // prevent typing "/" into any active input
        event.preventDefault();
        // focus the search bar
        searchInputRef.current && searchInputRef.current.focus();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    // cleanup function
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const fetchSearchResults = async (term: string) => {
    setLoading(true);
    let res = await search(term);
    let formattedRes = resultsToItems(res);
    setResults(formattedRes);
    setLoading(false);
  };
  const debouncedFetch = useMemo(() => debounce(fetchSearchResults, 200), []);

  const handleClick = (
    type: "artists" | "collections" | "assets",
    result: any
  ) => {
    setInput("");

    let path = result.id;

    if (type === "assets" && result.contractAddress && result.tokenId) {
      path = result.contractAddress + "/" + result.tokenId;
    }

    navigateToPage(type === "assets" ? "asset" : type, path);
  };

  const navigateToPage = (
    type: "artists" | "collections" | "asset",
    path: string
  ) => {
    // using window.location to refresh a hard refresh
    window.location.href =
      window.location.origin + `${getBasePath()}/${type}/${path}`;
  };

  const getResultsState = () => {
    if (loading) {
      return "loading";
    } else if (results?.length === 0) {
      return "empty";
    } else {
      return "results";
    }
  };

  return (
    <>
      <FrameSearchBar
        query={input}
        setQuery={setInput}
        active={searchActive}
        onFocus={() => setSearchActive(true)}
        onBlur={() => setSearchActive(false)}
        inputRef={searchInputRef}
      />
      {input.length ? (
        <div className="absolute w-full transform transition top-11 right-0">
          <FrameSearchResults
            handleClick={handleClick}
            results={results}
            state={getResultsState()}
          />
        </div>
      ) : null}
    </>
  );
};

const resultsToItems = (results: any) => {
  let items = [];

  if (results?.collections && results?.collections?.length > 0) {
    items.push({
      title: "Collections",
      items: results.collections.map((collection: any) => ({
        id: collection._id,
        imageUrl: collection.imageUrl,
        title: collection.name,
        subline: collection.artistName,
      })),
    });
  }

  if (results?.artists && results?.artists?.length > 0) {
    items.push({
      title: "Artists",
      items: results.artists.map((artist: any) => ({
        id: artist._id,
        imageUrl: artist.avatarUrl,
        title: artist.name,
        imageRounding: "full",
      })),
    });
  }

  if (results?.assets && results?.assets?.length > 0) {
    items.push({
      title: "Assets",
      items: results.assets.map((asset: any) => ({
        id: asset._id,
        contractAddress: asset.contractAddress,
        tokenId: asset.tokenId,
        imageUrl: asset.imageUrl,
        title: asset.name,
      })),
    });
  }

  return items;
};

export default Search;
