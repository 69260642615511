import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Close = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.25 6.75L6.75 17.25"
        stroke="currentColor"
        strokeWidth={1.5}
      />
      <path
        d="M6.75 6.75L17.25 17.25"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </svg>
  );
};
