import { PrivyProvider as PrivyProviderRaw } from "@privy-io/react-auth";

const PRIVY_APP_ID = process.env.NEXT_PUBLIC_PRIVY_APP_ID || "";
const PRIVY_CLIENT_ID = process.env.NEXT_PUBLIC_PRIVY_CLIENT_ID;
if (!PRIVY_APP_ID) throw new Error("Missing PRIVY_APP_ID");

export function PrivyProvider({ children }: { children: React.ReactNode }) {
  return (
    <PrivyProviderRaw
      appId={PRIVY_APP_ID}
      clientId={PRIVY_CLIENT_ID}
      config={{
        embeddedWallets: {
          createOnLogin: "users-without-wallets",
        },
      }}
    >
      {children}
    </PrivyProviderRaw>
  );
}
