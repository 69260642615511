import { Alert } from "../../icons/alert";
import { Chevron } from "../../icons/chevron";
import Avatar from "../avatars/Avatar";

interface Dropdown {
  weight?: "normal" | "medium";
  packed?: boolean;
  color?: "primary" | "secondary";
  title: string;
  open?: boolean;
  setOpen: any;
  avatar?: string;
  inline?: boolean;
  icon?: any;
  notification?: boolean;
}

// TODO: Add a doc comment explaining what all the props are for
const Dropdown = ({
  weight = "normal",
  packed = true,
  title,
  color = "primary",
  open = false,
  setOpen,
  avatar,
  inline = true,
  icon,
  notification,
}: Dropdown) => {
  const includesSorter = title.includes(":");
  const [beforeColon, afterColon] = title.split(":");

  return (
    <button
      onClick={() => setOpen((prev: boolean) => !prev)}
      className={`flex h-11 ${
        !inline
          ? "px-2 rounded-md hover:bg-[rgba(0,0,0,.05)]"
          : "hover:opacity-70"
      } transition items-center ${!packed ? "justify-between w-full" : null}`}
    >
      <div className="flex items-center gap-2">
        {avatar ? (
          <div className="relative">
            <Avatar imageUrl={avatar} size="24" rounding="full" />
            {notification ? (
              <Alert className="absolute w-3 h-3 text-white bg-red-600 rounded-full -top-1 -right-1" />
            ) : null}
          </div>
        ) : null}
        {icon ? <div className="-mr-2">{icon}</div> : null}
        <div
          className={`p-m text-left ${
            weight === "medium" ? "font-medium" : null
          } ${
            color === "secondary"
              ? "text-neutral-600 dark:text-neutral-200"
              : "dark:text-white"
          }`}
        >
          {includesSorter ? (
            <span className="flex ">
              <p className="text-neutral-400 dark:text-neutral-500 mr-1">
                {beforeColon}:
              </p>
              <p>{afterColon}</p>
            </span>
          ) : (
            title
          )}
        </div>
      </div>

      <Chevron
        className={`${
          color === "secondary"
            ? "text-neutral-600 dark:text-neutral-200"
            : "dark:text-white"
        } transition ${open ? "transform rotate-180" : null}`}
      />
    </button>
  );
};

export default Dropdown;
