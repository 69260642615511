import { useSyncMachineWithArtBlocksClient } from "@/client/components/hooks/useSyncMachineWithWeb3Provider";
import { createBrowserInspector } from "@/client/lib/helpers";
import { excessSettlementFundsClaimMachine } from "@artblocks/sdk/dist/machines/excess-settlement-funds-claim-machine";
import { useSelector } from "@xstate/react";
import { useEffect } from "react";
import { ActorRefFrom } from "xstate";
import { ExcessSettlementFundsManagerMachineContext } from ".";
import { triggerToast } from "../toast";
import { useExcessSettlementFundsClaimMachineRefs } from "./hooks";
import { useArtBlocksClient } from "../ArtBlocksProvider";

const { inspect } = createBrowserInspector();

export function ExcessSettlementFundsManagerMachineContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const artblocksClient = useArtBlocksClient();

  return (
    <ExcessSettlementFundsManagerMachineContext.Provider
      options={{ input: { artblocksClient }, inspect }}
    >
      <ExcessSettlementFundsManagerMachineManager />
      {children}
    </ExcessSettlementFundsManagerMachineContext.Provider>
  );
}

function ExcessSettlementFundsManagerMachineManager() {
  const managerMachine =
    ExcessSettlementFundsManagerMachineContext.useActorRef();
  useSyncMachineWithArtBlocksClient(managerMachine);

  const excessSettlementFundClaimMachineRefs =
    useExcessSettlementFundsClaimMachineRefs();

  const claimMachineEntries = [
    ...(excessSettlementFundClaimMachineRefs?.entries() ?? []),
  ];

  return (
    <>
      {claimMachineEntries.map(([claimId, claimMachineRef]) => (
        <ExcessSettlementFundsClaimNotifier
          key={claimId}
          claimMachineRef={claimMachineRef}
        />
      ))}
    </>
  );
}

const TOAST_DURATION = 5000;
function ExcessSettlementFundsClaimNotifier({
  claimMachineRef,
}: {
  claimMachineRef: ActorRefFrom<typeof excessSettlementFundsClaimMachine>;
}) {
  const claimState = useSelector(
    claimMachineRef,
    (state) => state,
    (a, b) => {
      return a.value === b.value;
    }
  );

  const isErrored = claimState.matches("error");
  useEffect(() => {
    if (isErrored) {
      triggerToast({
        type: "error",
        message: "Error claiming funds",
        options: {
          position: "bottom-right",
          duration: TOAST_DURATION,
        },
      });
    }
  }, [isErrored]);

  const isSuccessful = claimState.matches("success");
  useEffect(() => {
    if (isSuccessful) {
      triggerToast({
        type: "success",
        message: "Funds claimed successfully",
        options: {
          position: "bottom-right",
          duration: TOAST_DURATION,
        },
      });
    }
  }, [isSuccessful]);

  return null;
}
